import { Button, ButtonGroup, FormControl, MenuItem, Select } from '@mui/material';
import React, { useCallback, useState } from 'react';

export interface SelectedValue {
  id: string | number;
  label: string;
  [key: string]: any;
}

interface SelectProps {
  label?: string;
  placeholder?: string;
  selectedValue: SelectedValue | undefined;
  options: SelectedValue[];
  onSelect(value: SelectedValue | undefined): void;
}

const SelectInput = ({ selectedValue, onSelect, options, label, placeholder }: SelectProps) => {
  const [_value, setValue] = useState(selectedValue || undefined);

  const onSelectValue = useCallback((event) => {
    setValue(event?.target?.value ?? '');
  }, []);

  const onReset = useCallback(() => {
    setValue(undefined);
  }, []);

  const onSubmit = useCallback(() => {
    return onSelect(_value);
  }, [onSelect, _value]);

  const renderSelectDefaultValue = () => <MenuItem disabled>{placeholder}</MenuItem>;

  return (
    <div className="referral__specialty">
      <label>{label}</label>
      <FormControl fullWidth className="select-code">
        <Select
          displayEmpty
          value={_value}
          onChange={onSelectValue}
          renderValue={_value?.id ? undefined : renderSelectDefaultValue}
        >
          {options.map((option: any) => (
            <MenuItem value={option} key={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <ButtonGroup variant="outlined">
          <Button onClick={onReset}>
            <i className="icon-x" />
          </Button>
          <Button onClick={onSubmit} disabled={!_value?.id}>
            <i className="icon-check" />
          </Button>
        </ButtonGroup>
      </FormControl>
    </div>
  );
};

export default SelectInput;
