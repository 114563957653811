import React from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { DetailsCardItem } from '@getvim/atomic-ui';

const OrderInfo = ({ order }: { order: EHR.Order }) => {
  return (
    <div>
      <div className="section-with-separator">
        <h2>Identifiers</h2>
        {order?.identifiers?.ehrOrderId && (
          <DetailsCardItem label="Order ID" value={order?.identifiers?.ehrOrderId} />
        )}
        {order?.basicInformation?.ehrEncounterId && (
          <DetailsCardItem label="Encounter ID" value={order?.basicInformation?.ehrEncounterId} />
        )}
      </div>
      <div className="section-with-separator">
        <h2>Basic Information</h2>
        {order?.basicInformation?.createdDate && (
          <DetailsCardItem label="Created date" value={order?.basicInformation?.createdDate} />
        )}
        {order?.basicInformation?.type && (
          <DetailsCardItem label="Type" value={order?.basicInformation.type} />
        )}
      </div>
    </div>
  );
};

export default OrderInfo;
