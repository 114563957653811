import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';

const getEncounterStatus = (encounterStatus) => {
  switch (encounterStatus?.toUpperCase()) {
    case 'LOCKED':
      return 'Locked';
    case 'UNLOCKED':
      return 'Unlocked';
    default:
      'Undefined';
  }
};

const EncounterDetails = ({ encounter }: { encounter: EHR.Encounter | undefined }) => {
  return (
    ((encounter?.identifiers?.ehrEncounterId ||
      encounter?.basicInformation?.encounterDateOfService) && (
      <>
        <div className="section-with-separator">
          <h2>Identifiers</h2>
          {encounter?.identifiers?.ehrEncounterId && (
            <DetailsCardItem label="Encounter ID" value={encounter?.identifiers?.ehrEncounterId} />
          )}
        </div>
        <div className="section-with-separator">
          <h2>Basic Information</h2>
          {encounter?.basicInformation?.encounterDateOfService && (
            <DetailsCardItem
              label="Encounter date"
              value={encounter?.basicInformation.encounterDateOfService}
            />
          )}
          <DetailsCardItem
            label="Status"
            value={getEncounterStatus(encounter?.basicInformation?.status)}
          />
        </div>
      </>
    )) ||
    null
  );
};

export default EncounterDetails;
