import { useContext, useState, useEffect } from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { VimOSContext } from '../vimOsContext';

export const useVimOSReferral = (): EHR.Referral | undefined => {
  const vimOS = useContext(VimOSContext);
  const [referral, setReferral] = useState<EHR.Referral | undefined>(undefined);
  useEffect(() => {
    if (vimOS.ehr) {
      vimOS.ehr.subscribe('referral', setReferral);
      return () => {
        vimOS.ehr.unsubscribe('referral', setReferral);
      };
    }
  }, [vimOS]);
  return referral;
};
