import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';

interface UserEHRInfoProps {
  ehrVendor: string | undefined;
}

const UserEHRInfo = ({ ehrVendor }: UserEHRInfoProps) => {
  return ehrVendor ? (
    <div className="section-with-separator">
      <h2>EHR Information</h2>
      {ehrVendor && <DetailsCardItem label="EHR type" value={ehrVendor} />}
    </div>
  ) : (
    <></>
  );
};

export default UserEHRInfo;
