import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';

const UNDEFINED_PROVIDER_DATA = 'Undefined';

const ReferralProvider = ({
  title,
  provider,
}: {
  title: string;
  provider: EHR.Referral['referringProvider'] | EHR.Referral['targetProvider'];
}) => {
  return (
    <div>
      <h2>{title}</h2>

      <div className="section-with-separator">
        <h2>Provider Identifiers</h2>

        <DetailsCardItem label="NPI" value={provider?.npi || UNDEFINED_PROVIDER_DATA} />
        <DetailsCardItem
          label="EHR ID"
          value={provider?.ehrProviderId || UNDEFINED_PROVIDER_DATA}
        />
      </div>

      <div className="section-with-separator">
        <h2>Provider Demographics</h2>

        <DetailsCardItem
          label="First name"
          value={provider?.demographics.firstName || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="Last name"
          value={provider?.demographics.lastName || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="Middle name"
          value={provider?.demographics?.middleName || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="Degree"
          value={provider?.providerDegree || UNDEFINED_PROVIDER_DATA}
        />
      </div>

      <div className="section-with-separator">
        <h2>Provider Contact Information</h2>

        <DetailsCardItem
          label="Email"
          value={provider?.facility?.contact_info?.email || UNDEFINED_PROVIDER_DATA}
        />
        {/*<DetailsCardItem label="Fax number" value={``} />*/}
        {/*<DetailsCardItem label="Home phone number" value={``} />*/}
        <DetailsCardItem
          label="Mobile phone number"
          value={provider?.facility?.contact_info?.mobilePhoneNumber || UNDEFINED_PROVIDER_DATA}
        />
      </div>

      <div className="section-with-separator">
        <h2>Provider Organization Address</h2>

        <DetailsCardItem
          label="Address"
          value={provider?.facility?.address?.fullAddress || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="Address 1"
          value={provider?.facility?.address?.address1 || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="City"
          value={provider?.facility?.address?.city || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="State"
          value={provider?.facility?.address?.state || UNDEFINED_PROVIDER_DATA}
        />
        <DetailsCardItem
          label="Zip Code"
          value={provider?.facility?.address?.zipCode || UNDEFINED_PROVIDER_DATA}
        />
      </div>
    </div>
  );
};

export default ReferralProvider;
