import React, { useCallback, useContext } from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';
import EditableTextInput from '../common/editable-text-input/EditableTextInput';
import SelectInput, { SelectedValue } from '../common/select/Select';
import staticPriorities from '../../store/staticPriorities.json';
import DataPickerInput from '../common/data-picker/DataPickerInput';
import { VimOSContext } from '../../vimOsContext';
import { useVimWritebackWrapper } from '../../hooks/useVimWritebackWrapper';

const ReferralInfo = ({
  referral,
  encounter,
}: {
  referral: EHR.Referral | undefined;
  encounter: EHR.Encounter | undefined;
}) => {
  const writeback = useVimWritebackWrapper();
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;

  const onSubmitNotes = useCallback(
    async (value: string) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { notes: value },
        }),
      );
    },
    [resourceUpdater, writeback],
  );
  const onSubmitReasons = useCallback(
    async (value: string) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { reasons: [value] },
        }),
      );
    },
    [resourceUpdater, writeback],
  );
  const onSubmitAuthCode = useCallback(
    async (value: string) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { authCode: value },
        }),
      );
    },
    [resourceUpdater, writeback],
  );

  const onSubmitPriority = useCallback(
    async (value: SelectedValue) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { priority: value.label.toUpperCase() as EHR.ReferralPriority },
        }),
      );
    },
    [resourceUpdater, writeback],
  );
  const onSubmitStartDate = useCallback(
    async (value: string) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { startDate: value },
        }),
      );
    },
    [resourceUpdater, writeback],
  );
  const onSubmitEndDate = useCallback(
    (value: string) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { endDate: value },
        }),
      );
    },
    [resourceUpdater, writeback],
  );

  const onSubmitNumberOfVisits = useCallback(
    (value: string) => {
      writeback(
        resourceUpdater.updateReferral({
          basicInformation: { numberOfVisits: value },
        }),
      );
    },
    [resourceUpdater, writeback],
  );

  const selectedPriority = staticPriorities.find(
    (item) => item.label == referral?.basicInformation?.priority,
  );

  return (
    (referral?.identifiers?.ehrReferralId && (
      <div>
        <div className="referral section-with-separator">
          <h2>Identifiers</h2>
          {referral?.identifiers?.ehrReferralId && (
            <DetailsCardItem label="Referral ID" value={referral?.identifiers?.ehrReferralId} />
          )}
        </div>

        {encounter?.basicInformation?.encounterDateOfService && (
          <div className="referral section-with-separator">
            <h2>Basic information</h2>
            {referral?.basicInformation?.createdDate && (
              <DetailsCardItem
                label="Encounter date"
                value={encounter?.basicInformation?.encounterDateOfService}
              />
            )}
          </div>
        )}

        <div className="referral section-with-separator">
          <h2>Basic Information</h2>

          <DataPickerInput
            label="Start date"
            value={referral?.basicInformation?.startDate ?? ''}
            handleOnSave={onSubmitStartDate}
          />

          <DataPickerInput
            label="End date"
            value={referral?.basicInformation?.endDate ?? ''}
            handleOnSave={onSubmitEndDate}
          />

          {referral?.basicInformation?.createdDate && (
            <DetailsCardItem label="Created at" value={referral?.basicInformation?.createdDate} />
          )}

          <SelectInput
            label="Priority"
            placeholder="Select priority"
            selectedValue={selectedPriority}
            options={staticPriorities}
            onSelect={onSubmitPriority}
          />

          {referral?.basicInformation?.status && (
            <DetailsCardItem label="Status" value={referral?.basicInformation?.status} />
          )}

          {referral?.basicInformation?.numberOfVisits && (
            <EditableTextInput
              value={referral?.basicInformation?.numberOfVisits}
              label="Number of visits"
              handleOnSave={onSubmitNumberOfVisits}
            />
          )}

          <EditableTextInput
            value={referral?.basicInformation?.reasons?.[0] ?? ''}
            label="Reasons"
            handleOnSave={onSubmitReasons}
          />

          <EditableTextInput
            value={referral?.basicInformation?.notes ?? ''}
            label="Notes"
            handleOnSave={onSubmitNotes}
          />

          {referral?.basicInformation?.authCode && (
            <EditableTextInput
              value={referral?.basicInformation?.authCode}
              label="Auth code"
              handleOnSave={onSubmitAuthCode}
            />
          )}
        </div>
      </div>
    )) ||
    null
  );
};

export default ReferralInfo;
