import React, { useEffect, useState } from 'react';
import { loadSdk } from 'vim-os-js-browser';
import { SDK } from 'vim-os-js-browser/types';
import { VimOSContext } from './vimOsContext';

export const AppWrapper = ({ children }) => {
  const [vimOS, setVimOS] = useState(undefined);
  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const vimSdk = await loadSdk({} as SDK);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setVimOS(vimSdk);
    })();
  }, []);

  if (!vimOS) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <VimOSContext.Provider value={vimOS}>{children}</VimOSContext.Provider>;
};
