import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';

interface UserOrgInfoProps {
  organization?: {
    name: string;
    id: number;
  };
}

const UserOrgInfo = ({ organization }: UserOrgInfoProps) => {
  return (
    <div className="section-with-separator last">
      <h2>Organization</h2>
      {organization?.name && (
        <DetailsCardItem label="Organization name" value={organization.name} />
      )}
    </div>
  );
};

export default UserOrgInfo;
