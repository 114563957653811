export function isObjectEmpty(obj: any): boolean {
  if (typeof obj !== 'object' || obj === null) {
    return obj === undefined || obj === null;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'function') {
        continue; // Skip functions at the first level
      }
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!isObjectEmpty(obj[key])) {
          return false;
        }
      } else if (obj[key] !== undefined && obj[key] !== null) {
        return false;
      }
    }
  }

  return true;
}
