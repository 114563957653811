import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { EHR } from 'vim-os-js-browser/types';

const PatientInsurance = ({ patient }: { patient: EHR.Patient | undefined }) => {
  return patient?.insurance ? (
    <div className="section-with-separator">
      <h2>Insurance</h2>
      <DetailsCardItem label="Insurer name" value={patient.insurance.ehrInsurance ?? 'undefined'} />
      <DetailsCardItem label="Member ID" value={patient.insurance.memberId ?? 'undefined'} />
    </div>
  ) : (
    <></>
  );
};

export default PatientInsurance;
