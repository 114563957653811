import React, { FC, useState, useCallback, useContext } from 'react';
import { FormControl, MenuItem, Select, Button, ButtonGroup } from '@mui/material';
import staticIcds from '../../store/staticIcds.json';
import { useNotifications } from '../../hooks';
import type { EHR } from 'vim-os-js-browser/types';
import { VimOSContext } from '../../vimOsContext';

type Diagnosis = { code: string; description: string };
interface SelectIcdToWritebackProps {
  resourceType: EHR.EHRResource;
}

const SelectIcdToWriteback: FC<SelectIcdToWritebackProps> = ({ resourceType }) => {
  const [selectedIcd, setSelectedIcd] = useState<string>('');
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;
  const notifications = useNotifications();

  const onIcdSelect = useCallback((event) => {
    setSelectedIcd(event?.target?.value ?? '');
  }, []);

  const triggerWriteback = useCallback(async () => {
    try {
      const diagnosisToAdd = JSON.parse(selectedIcd);
      switch (resourceType) {
        case 'encounter': {
          await resourceUpdater.updateEncounter({
            assessment: {
              diagnosisCodes: [diagnosisToAdd],
            },
          });
          break;
        }
        /** waiting for implementation from vim-os side */
        // case EHR.EHRResource.orders: {
        //   const encounterBuilder = resourceUpdate.orderBuilder();
        //   encounterBuilder.addDiagnoses([diagnosisToAdd]);
        //   await encounterBuilder.commit();
        //   break;
        // }
        case 'referral': {
          await resourceUpdater.updateReferral({
            conditions: { diagnosis: [diagnosisToAdd] },
          });
          break;
        }
        default:
          throw new Error(`Resource update of type ${resourceType} is not supported`);
      }
      notifications.updateResourceWriteback(true);
    } catch (error) {
      console.log('Error:', error);
      notifications.updateResourceWriteback(false);
    } finally {
      setSelectedIcd('');
    }
  }, [notifications, resourceUpdater, selectedIcd, resourceType]);

  const renderSelectDefaultValue = () => <MenuItem disabled>Select ICD-10</MenuItem>;

  return (
    <FormControl fullWidth className="select-code">
      <Select
        displayEmpty
        value={selectedIcd}
        onChange={onIcdSelect}
        renderValue={selectedIcd.length > 0 ? undefined : renderSelectDefaultValue}
      >
        {staticIcds.map((diagnosis: Diagnosis) => (
          <MenuItem value={JSON.stringify(diagnosis)} key={diagnosis.code}>
            {diagnosis.code} - {diagnosis.description}
          </MenuItem>
        ))}
      </Select>
      <ButtonGroup variant="outlined">
        <Button onClick={onIcdSelect}>
          <i className="icon-x" />
        </Button>
        <Button onClick={triggerWriteback} disabled={selectedIcd.length === 0}>
          <i className="icon-check" />
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};
export default SelectIcdToWriteback;
