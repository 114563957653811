import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { EHR } from 'vim-os-js-browser/types';

const PatientAddress = ({ patient }: { patient: EHR.Patient | undefined }) => {
  return patient?.address ? (
    <div className="section-with-separator">
      <h2>Address</h2>
      {patient?.address?.city && <DetailsCardItem label="City" value={patient.address.city} />}
      {patient?.address?.state && <DetailsCardItem label="State" value={patient.address.state} />}
      {patient?.address?.zipCode && <DetailsCardItem label="Zip" value={patient.address.zipCode} />}
      {patient?.address?.fullAddress && (
        <DetailsCardItem label="Full address" value={patient.address.fullAddress} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default PatientAddress;
