import React, { useState, useCallback, useContext } from 'react';
import { FormControl, MenuItem, Select, Button, ButtonGroup } from '@mui/material';
import EditableInput from '../common/editable-text-area-input/EditableTextAreaInput';
import staticCpts from '../../store/staticCpts.json';
import { useNotifications } from '../../hooks';
import { useVimWritebackWrapper } from '../../hooks/useVimWritebackWrapper';
import { VimOSContext } from '../../vimOsContext';

const EncounterPlan = () => {
  const [selectedCpt, setSelectedCpt] = useState<string>('');
  const [generalNotes, setGeneralNotes] = useState<string>('');
  const writeback = useVimWritebackWrapper();
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;
  const notifications = useNotifications();

  const onCptSelect = useCallback((event) => {
    setSelectedCpt(event?.target?.value ?? '');
  }, []);

  const triggerWriteback = useCallback(async () => {
    try {
      const treatmentToAdd = JSON.parse(selectedCpt);
      await resourceUpdater.updateEncounter({
        plan: {
          procedureCodes: [treatmentToAdd],
        },
      });
      notifications.updateResourceWriteback(true);
    } catch {
      notifications.updateResourceWriteback(false);
    } finally {
      setSelectedCpt('');
    }
  }, [resourceUpdater, selectedCpt, notifications]);

  const onSubmitGeneralNotes = useCallback(
    async (generalNotes: string) => {
      setGeneralNotes(generalNotes);
      return writeback(resourceUpdater.updateEncounter({ plan: { generalNotes } }));
    },
    [resourceUpdater, writeback],
  );

  const renderSelectDefaultValue = () => <MenuItem disabled>Select CPT</MenuItem>;

  return (
    <div className="section-with-separator">
      <h2>Plan</h2>
      <FormControl fullWidth className="select-code">
        <Select
          displayEmpty
          value={selectedCpt}
          onChange={onCptSelect}
          renderValue={selectedCpt.length > 0 ? undefined : renderSelectDefaultValue}
        >
          {staticCpts.map((cpt) => (
            <MenuItem value={JSON.stringify(cpt)} key={cpt.code}>
              {cpt.code} - {cpt.description}
            </MenuItem>
          ))}
        </Select>
        <ButtonGroup variant="outlined">
          <Button onClick={onCptSelect}>
            <i className="icon-x" />
          </Button>
          <Button onClick={triggerWriteback} disabled={selectedCpt.length === 0}>
            <i className="icon-check" />
          </Button>
        </ButtonGroup>
      </FormControl>
      <EditableInput
        value={generalNotes}
        label="General notes"
        placeholder="Add notes here"
        handleOnSave={onSubmitGeneralNotes}
      />
    </div>
  );
};
export default EncounterPlan;
