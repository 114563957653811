import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';
import { toSentenceCase } from '../../utils';

const ReferralProcedures = ({ referral }: { referral: EHR.Referral | undefined }) => {
  return (
    <div className="section-with-separator">
      <h2>Procedure Codes</h2>
      {referral?.procedureCodes?.cpts?.map((procedure) => {
        return (
          <DetailsCardItem
            value={
              (
                <>
                  <strong>{procedure.code ? procedure.code.toUpperCase() : procedure.code}</strong>
                  &nbsp;-&nbsp;
                  {toSentenceCase(procedure.description)}
                </>
              ) as React.ReactNode
            }
            key={procedure.code}
          />
        );
      })}
    </div>
  );
};

export default ReferralProcedures;
