import React, { useEffect, useState } from 'react';
import { DetailsCard, DetailsCardItem } from '@getvim/atomic-ui';
import PatientDemographics from './PatientDemographics';
import PatientInsurance from './PatientInsurance';
import PatientAddress from './PatientAddress';
import PatientContactInfo from './PatientContactInfo';
import PatientProblemList from './PatientProblemList';
import { EHR } from 'vim-os-js-browser/types';
import { isObjectEmpty } from '../../utils/isObjectEmpty';

const PatientCard = ({ patient }: { patient: EHR.Patient | undefined }) => {
  const [problemsList, setProblemsList] = useState<EHR.Diagnosis[]>([]);
  useEffect(() => {
    if (patient && patient.identifiers?.ehrPatientId) {
      patient
        .getProblemList()
        .then((res) => {
          setProblemsList(res ?? []);
        })
        .catch((error) => {
          console.error('Get problem list', error);
        });
    } else if (problemsList.length > 0) {
      setProblemsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);
  return (
    <DetailsCard title="Patient" iconClass="icon-user-2">
      <PatientDemographics patient={patient} />
      <PatientAddress patient={patient} />
      <PatientContactInfo patient={patient} />
      <PatientInsurance patient={patient} />
      <PatientProblemList problemsList={problemsList} />
      {isObjectEmpty(patient) && !problemsList.length && (
        <div className="empty-card">
          <DetailsCardItem value="No patient information to display...." />
        </div>
      )}
    </DetailsCard>
  );
};

export default PatientCard;
