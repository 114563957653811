import React, { useCallback, useContext, useState } from 'react';
import EditableInput from '../common/editable-text-area-input/EditableTextAreaInput';
import { useVimWritebackWrapper } from '../../hooks/useVimWritebackWrapper';
import { VimOSContext } from '../../vimOsContext';

const EncounterPatientInstructions = () => {
  const [generalNotes, setGeneralNotes] = useState('');
  const writeback = useVimWritebackWrapper();
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;

  const onSubmitGeneralNotes = useCallback(
    async (generalNotes: string) => {
      setGeneralNotes(generalNotes);
      return writeback(resourceUpdater.updateEncounter({ patientInstructions: { generalNotes } }));
    },
    [resourceUpdater, writeback],
  );

  return (
    <div className="section-with-separator">
      <h2>Patient Instructions</h2>
      <EditableInput
        value={generalNotes}
        label="General notes"
        placeholder="Add notes here"
        handleOnSave={onSubmitGeneralNotes}
      />
    </div>
  );
};

export default EncounterPatientInstructions;
