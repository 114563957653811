import { useContext, useState, useEffect } from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { VimOSContext } from '../vimOsContext';

export const useVimOSOrders = (): EHR.Order[] | undefined => {
  const vimOS = useContext(VimOSContext);
  const [orders, setOrders] = useState<EHR.Order[] | undefined>(undefined);
  useEffect(() => {
    if (vimOS.ehr) {
      vimOS.ehr.subscribe('orders', setOrders);
      return () => {
        vimOS.ehr.unsubscribe('orders', setOrders);
      };
    }
  }, [vimOS]);
  return orders;
};
