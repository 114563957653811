import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { SessionContext } from 'vim-os-js-browser/types';

const UserContactInfo = ({ user }: { user: SessionContext.User }) => {
  return user?.contactInfo?.email ? (
    <div className="section-with-separator">
      <h2>Contact Information</h2>
      {/* {user.phoneNumber && <DetailsCardItem label="Phone number" value={user.phoneNumber} />} */}
      <DetailsCardItem label="Email" value={user.contactInfo.email} />
    </div>
  ) : (
    <></>
  );
};

export default UserContactInfo;
