import React, { useCallback, useContext } from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import staticSpecialities from '../../store/specialities.json';
import SelectInput, { SelectedValue } from '../common/select/Select';
import { useVimWritebackWrapper } from '../../hooks/useVimWritebackWrapper';
import { VimOSContext } from '../../vimOsContext';
import ReferralProvider from './ReferralProvider';
import staticTargetProviders from '../../store/staticTargetProviders.json';
import { omit } from 'lodash-es';

const ReferralTargetProvider = ({ referral }: { referral: EHR.Referral | undefined }) => {
  const writeback = useVimWritebackWrapper();
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;

  const onSubmitTargetProvider = useCallback(
    (value: SelectedValue) => {
      writeback(resourceUpdater.updateReferral({ targetProvider: omit(value, ['id', 'label']) }));
    },
    [resourceUpdater, writeback],
  );

  const selectedTargetProvider = staticTargetProviders.find(
    (item) => item.id === referral?.targetProvider?.ehrProviderId,
  );

  const onSubmitSpeciality = useCallback(
    (value: SelectedValue) => {
      writeback(resourceUpdater.updateReferral({ basicInformation: { specialty: value.label } }));
    },
    [resourceUpdater, writeback],
  );

  const selectedSpeciality = staticSpecialities.find(
    (item) => item.label === referral?.basicInformation?.specialty,
  );

  return (
    <div>
      <ReferralProvider title="Target Provider" provider={referral?.targetProvider} />

      <div className="referral section-with-separator">
        <h2>Target Provider Full Name</h2>
        <SelectInput
          label="Description"
          placeholder="Select provider name"
          selectedValue={selectedTargetProvider}
          options={staticTargetProviders}
          onSelect={onSubmitTargetProvider}
        />
      </div>

      <div className="referral section-with-separator last">
        <h2>Target Provider Specialty</h2>
        <SelectInput
          label="Description"
          placeholder="Select speciality"
          selectedValue={selectedSpeciality}
          options={staticSpecialities}
          onSelect={onSubmitSpeciality}
        />
      </div>
    </div>
  );
};

export default ReferralTargetProvider;
