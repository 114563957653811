import React from 'react';
import { DetailsCard, DetailsCardItem } from '@getvim/atomic-ui';
import { SessionContext } from 'vim-os-js-browser/types';
import UserDemographics from './UserDemographics';
import UserContactInfo from './UserContactInfo';
import UserOrgInfo from './UserOrgInfo';
import UserEHRInfo from './UserEHRInfo';

interface UserCardProps {
  user: SessionContext.User;
  ehrVendor: string | undefined;
  organization?: {
    name: string;
    id: number;
  };
}

const UserCard = ({ user, ehrVendor, organization }: UserCardProps) => {
  return (
    <DetailsCard title="User" iconClass="icon-user-c">
      {/* Add 2 empty divs because card must have at least 2 children to be collapsible*/}
      <div />
      <div />
      <UserDemographics user={user} />
      <UserContactInfo user={user} />
      <UserEHRInfo ehrVendor={ehrVendor} />
      {!!organization && <UserOrgInfo organization={organization} />}
      {!user?.identifiers?.ehrUsername &&
        !user?.contactInfo?.email &&
        !ehrVendor &&
        !organization?.name &&
        !user?.demographics?.firstName &&
        !user?.demographics?.lastName && (
          <div className="empty-card">
            <DetailsCardItem value="No user information to display...." />
          </div>
        )}
    </DetailsCard>
  );
};

export default UserCard;
