import React, { useContext, useEffect } from 'react';
import '@getvim/atomic-ui/assets/styles/main.less';
import '../App.less';
import UserCard from './user-card/UserCard';
import PatientCard from './patient-card/PatientCard';
import EncounterCard from './encounter-card/EncounterCard';
import ReferralCard from './referral-card/ReferralCard';
import OrderCard from './order-card/OrderCard';
import { Toast } from '@getvim/atomic-ui';
import { VimOSContext } from '../vimOsContext';
import { useVimOSReferral, useVimOSPatient, useVimOSEncounter } from '../hooks';
import { useVimOSOrders } from '../hooks/useVimOsOrders';

const { ToastContainer, Slide } = Toast;

const DemoCanvasAppContainer = () => {
  const { hub, sessionContext } = useContext(VimOSContext);
  const patient = useVimOSPatient();
  const referral = useVimOSReferral();
  const encounter = useVimOSEncounter();
  const orders = useVimOSOrders();
  const ehrState = {
    patient,
    referral,
    encounter,
    orders,
  };
  const { user, organization, ehrType } = sessionContext;

  // Activate app when session starts
  useEffect(() => {
    hub.setActivationStatus('ENABLED');
  }, [hub, hub.setActivationStatus]);

  const shouldShowEncounterCard = ehrState.encounter?.identifiers?.ehrEncounterId;
  const shouldShowReferralCard =
    ehrState.referral?.identifiers?.ehrReferralId ||
    ehrState.referral?.basicInformation?.specialty ||
    ehrState.referral?.identifiers?.vimReferralId;
  const shouldShowOrderCard = ehrState.orders && ehrState.orders.length > 0;

  return (
    <div className="cards-container">
      <UserCard user={user} ehrVendor={ehrType} organization={organization?.identifiers} />
      {ehrState.patient && <PatientCard patient={ehrState.patient} />}
      {shouldShowEncounterCard && <EncounterCard encounter={ehrState.encounter} />}
      {shouldShowReferralCard && (
        <ReferralCard referral={ehrState.referral} encounter={ehrState.encounter} />
      )}
      {shouldShowOrderCard && <OrderCard orders={ehrState.orders} />}
      <ToastContainer
        className=""
        position="bottom-right"
        hideProgressBar
        autoClose={3000}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
    </div>
  );
};

export default DemoCanvasAppContainer;
