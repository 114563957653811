import { useContext, useState, useEffect } from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { VimOSContext } from '../vimOsContext';

export const useVimOSPatient = (): EHR.Patient | undefined => {
  const vimOS = useContext(VimOSContext);
  const [patient, setPatient] = useState<EHR.Patient | undefined>(undefined);
  useEffect(() => {
    if (vimOS.ehr) {
      vimOS.ehr.subscribe('patient', setPatient);
      return () => {
        vimOS.ehr.unsubscribe('patient', setPatient);
      };
    }
  }, [vimOS]);
  return patient;
};
