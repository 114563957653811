import React from 'react';
import { DetailsCard } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';
import './ReferralCard.less';
import ReferralInfo from './ReferralInfo';
import ReferralProvider from './ReferralProvider';
import ReferralTargetProvider from './ReferralTargetProvider';
import ReferralAssessment from './ReferralAssessment';
import ReferralProcedures from './ReferralProcedures';

const ReferralCard = ({
  referral,
  encounter,
}: {
  referral: EHR.Referral | undefined;
  encounter: EHR.Encounter | undefined;
}) => {
  return (
    <DetailsCard title="Referral" iconClass="icon-referral">
      {/* Add 2 empty divs because card must have at least 2 children to be collapsible*/}
      <div />
      <div />
      <ReferralInfo referral={referral} encounter={encounter} />
      <ReferralAssessment referral={referral} />
      <ReferralProcedures referral={referral} />
      <ReferralProvider title="Referring Provider" provider={referral?.referringProvider} />
      <ReferralTargetProvider referral={referral} />
    </DetailsCard>
  );
};

export default ReferralCard;
