import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { EHR } from 'vim-os-js-browser/types';

const PatientProblemList = ({ problemsList }: { problemsList: EHR.Diagnosis[] }) => {
  return problemsList?.length > 0 ? (
    <div className="section-with-separator last">
      <h2>Problem List</h2>
      {problemsList.map((problem) => {
        return (
          <DetailsCardItem
            key={`key-${problem.code}`}
            value={
              (
                <>
                  <strong>{problem.code ? problem.code.toUpperCase() : problem.code}</strong>
                  &nbsp;-&nbsp;
                  {problem.description}
                </>
              ) as React.ReactNode
            }
          />
        );
      })}
    </div>
  ) : null;
};

export default PatientProblemList;
