import React, { useCallback, useContext, useState } from 'react';
import EditableInput from '../common/editable-text-area-input/EditableTextAreaInput';
import { useVimWritebackWrapper } from '../../hooks/useVimWritebackWrapper';
import { VimOSContext } from '../../vimOsContext';

const EncounterSubjective = () => {
  /** Should be appointment metadata info there in future   */
  //const { encounter } = props;
  const writeback = useVimWritebackWrapper();
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;

  const [historyOfPresentIllnessNotes, setHistoryOfPresentIllnessNotes] = useState('');
  const [reviewOfSystemsNotes, setReviewOfSystemsNotes] = useState('');
  const [generalNotes, setGeneralNotes] = useState('');
  const [chiefComplaintNotes, setChiefComplaintNotes] = useState('');

  const onSubmitHistoryOfPresentIllnessNotes = useCallback(
    (historyOfPresentIllnessNotes: string) => {
      setHistoryOfPresentIllnessNotes(historyOfPresentIllnessNotes);
      return writeback(
        resourceUpdater.updateEncounter({ subjective: { historyOfPresentIllnessNotes } }),
      );
    },
    [resourceUpdater, writeback],
  );

  const onSubmitReviewOfSystemsNotes = useCallback(
    (reviewOfSystemsNotes: string) => {
      setReviewOfSystemsNotes(reviewOfSystemsNotes);
      return writeback(resourceUpdater.updateEncounter({ subjective: { reviewOfSystemsNotes } }));
    },
    [resourceUpdater, writeback],
  );

  const onSubmitGeneralNotes = useCallback(
    (generalNotes: string) => {
      setGeneralNotes(generalNotes);
      return writeback(resourceUpdater.updateEncounter({ subjective: { generalNotes } }));
    },
    [resourceUpdater, writeback],
  );

  const onSubmitChiefComplaintNotes = useCallback(
    (chiefComplaintNotes: string) => {
      setChiefComplaintNotes(chiefComplaintNotes);
      return writeback(resourceUpdater.updateEncounter({ subjective: { chiefComplaintNotes } }));
    },
    [resourceUpdater, writeback],
  );

  return (
    <div className="section-with-separator">
      <h2>Subjective</h2>
      <div>
        <EditableInput
          value={generalNotes}
          label="General notes"
          placeholder="Add notes here"
          handleOnSave={onSubmitGeneralNotes}
        />
      </div>
      <div>
        <EditableInput
          value={chiefComplaintNotes}
          label="Chief Complaint"
          placeholder="Add notes here"
          handleOnSave={onSubmitChiefComplaintNotes}
        />
      </div>

      <div>
        <EditableInput
          value={historyOfPresentIllnessNotes}
          label="History of Present Illness"
          placeholder="Add notes here"
          handleOnSave={onSubmitHistoryOfPresentIllnessNotes}
        />
      </div>
      <div>
        <EditableInput
          value={reviewOfSystemsNotes}
          label="Review Of Systems"
          placeholder="Add notes here"
          handleOnSave={onSubmitReviewOfSystemsNotes}
        />
      </div>
    </div>
  );
};

export default EncounterSubjective;
