import React from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { DetailsCardItem } from '@getvim/atomic-ui';

const OrderICDs = ({ order }: { order: EHR.Order }) => {
  return order?.assessments?.assessments?.length ? (
    <div className="section-with-separator">
      <h2>Assessments</h2>
      {order.assessments.assessments.map((diagnosis) => {
        return (
          <>
            <DetailsCardItem
              value={
                (
                  <>
                    <strong>
                      {diagnosis.code ? diagnosis.code.toUpperCase() : diagnosis.code}
                    </strong>
                    &nbsp;-&nbsp;
                    {diagnosis.description}
                    &nbsp;
                    <i>{`(${diagnosis.system})`}</i>
                  </>
                ) as React.ReactNode
              }
              key={diagnosis.code}
            />
            {/*<SelectIcdToWriteback resourceType={EHRResource.orders} />*/}
          </>
        );
      })}
    </div>
  ) : null;
};

export default OrderICDs;
