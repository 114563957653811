import React from 'react';
import { DetailsCard } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';
import './EncounterCard.less';
import EncounterDetails from './EncounterDetails';
import EncounterProviderDetails from './EncounterProviderDetails';
import EncounterSubjective from './EncounterSubjective';
import EncounterObjective from './EncounterObjective';
import EncounterAssessment from './EncounterAssessment';
import EncounterPlan from './EncounterPlan';
import EncounterPatientInstructions from './EncounterPatientInstructions';

const EncounterCard = ({ encounter }: { encounter: EHR.Encounter | undefined }) => {
  return (
    <DetailsCard title="Encounter" iconClass="icon-encounter">
      {/* Add 2 empty divs because card must have at least 2 children to be collapsible*/}
      <div />
      <div />
      <EncounterDetails encounter={encounter} />
      <EncounterSubjective />
      <EncounterObjective />
      <EncounterAssessment encounter={encounter} />
      <EncounterPlan />
      <EncounterPatientInstructions />
      <EncounterProviderDetails encounter={encounter} />
    </DetailsCard>
  );
};

export default EncounterCard;
