import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { SessionContext } from 'vim-os-js-browser/types';

const UserDemographics = ({ user }: { user: SessionContext.User }) => {
  return !user?.demographics && !user?.identifiers?.ehrUsername ? (
    <></>
  ) : (
    <div className="section-with-separator">
      <h2>Demographics</h2>
      {user?.demographics?.firstName && (
        <DetailsCardItem label="First name" value={user?.demographics?.firstName} />
      )}
      {user?.demographics?.lastName && (
        <DetailsCardItem label="Last name" value={user?.demographics?.lastName} />
      )}
      {user?.identifiers?.ehrUsername && (
        <DetailsCardItem label="EHR username" value={user?.identifiers?.ehrUsername} />
      )}
    </div>
  );
};

export default UserDemographics;
