import { useCallback, useEffect, useState } from 'react';
import { Toast } from '@getvim/atomic-ui';

export enum NotificationMessageMapping {
  WRITEBACK_SUCCESS = 'The EHR was updated based on your selection.',
  WRITEBACK_FAIL = 'Failed to update chart',
}

interface UseNotificationReturnValue {
  updateResourceWriteback: (success: boolean, errorMsg?: string) => void;
}

const { createToast, ToastTypes } = Toast;

export const useNotifications = (): UseNotificationReturnValue => {
  const [notification, setNotification] = useState<object | null>(null);

  useEffect(() => {
    if (notification) {
      createToast(notification as any);

      setNotification(null);
    }
  }, [notification, setNotification]);

  const updateResourceWriteback = useCallback(
    (success: boolean, errorMsg?: string) => {
      let notification;

      if (success) {
        notification = {
          title: 'All done!',
          message: NotificationMessageMapping.WRITEBACK_SUCCESS,
          type: ToastTypes.SUCCESS,
          html: true,
        };
      } else {
        notification = {
          title: 'Oops',
          message: errorMsg ?? NotificationMessageMapping.WRITEBACK_FAIL,
          type: ToastTypes.ERROR,
          html: true,
        };
      }
      setNotification(notification);
    },
    [setNotification],
  );

  return { updateResourceWriteback };
};
