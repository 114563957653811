import React from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { DetailsCard } from '@getvim/atomic-ui';
import OrderInfo from './OrderInfo';
import OrderICDs from './OrderICDs';
import OrderCPTs from './OrderCPTs';

const OrderCard = ({ orders }: { orders: EHR.Order[] | undefined }) => {
  return (
    <DetailsCard title="Order" iconClass="icon-scroll">
      {/* Add 2 empty divs because card must have at least 2 children to be collapsible*/}
      <div />
      <div />
      {/* We only look at the first order in context. Currently, multiple orders in context is not supported.*/}
      {orders?.length && <OrderInfo order={orders[0]} />}
      {orders?.length && <OrderICDs order={orders[0]} />}
      {orders?.length && orders[0]?.procedureCodes?.procedureCodes?.length && (
        <OrderCPTs order={orders[0]} />
      )}
    </DetailsCard>
  );
};

export default OrderCard;
