import { Button, ButtonGroup, TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import './index.less';

interface Props {
  value: string;
  label?: string;
  handleOnSave(value: string): void;
}

const EditableTextInput = (props: Props) => {
  const { value = '', label, handleOnSave } = props;

  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [_value, setValue] = useState(value);

  const handleOnChange = useCallback((event) => {
    setValue(event?.target?.value ?? '');
  }, []);

  const toggleEditMode = useCallback(() => {
    setIsEdited(!isEdited);
  }, [isEdited]);

  const cancelEditMode = useCallback(() => {
    setValue('');
    setIsEdited(false);
  }, []);

  const submit = useCallback(() => {
    setIsEdited(false);
    handleOnSave(_value);
  }, [_value, handleOnSave]);

  return (
    <div className="editable-text-input">
      <label>{label}</label>
      <div className="value-input">
        <TextField
          id="standard-basic"
          className="text-input"
          value={_value}
          disabled={!isEdited}
          onChange={handleOnChange}
        />

        {isEdited ? (
          <ButtonGroup variant="outlined" className="action-group">
            <Button onClick={cancelEditMode} className="action-button">
              <i className="icon-x" />
            </Button>
            <Button disabled={_value.length < 1} onClick={submit} className="action-button">
              <i className="icon-check" />
            </Button>
          </ButtonGroup>
        ) : (
          <Button
            onClick={toggleEditMode}
            className="edit-icon"
            sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
          >
            <i className="icon-pencil" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditableTextInput;
