import React from 'react';
import ReactDOM from 'react-dom/client';
import DemoCanvasAppContainer from './components/DemoCanvasAppContainer';
import { AppWrapper } from './AppWrapper';

function CanvasDemoApp() {
  return (
    <AppWrapper>
      <DemoCanvasAppContainer />
    </AppWrapper>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<CanvasDemoApp />);
