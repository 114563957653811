import { useCallback } from 'react';
import { useNotifications } from '.';

export const useVimWritebackWrapper = (): any => {
  const notifications = useNotifications();

  const writeback = useCallback(async (cb) => {
    try {
      await cb;
      notifications.updateResourceWriteback(true);
    } catch (e) {
      notifications.updateResourceWriteback(false, (e as any)?.data);
    }
  }, []);

  return writeback;
};
