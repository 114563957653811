import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';
import SelectIcdToWriteback from '../common/SelectIcdToWriteback';
import { toSentenceCase } from '../../utils';

const ReferralAssessment = ({ referral }: { referral: EHR.Referral | undefined }) => {
  return (
    <div className="section-with-separator">
      <h2>Conditions</h2>
      {referral?.conditions?.diagnosis?.map((diagnosis) => {
        return (
          <DetailsCardItem
            value={
              (
                <>
                  <strong>{diagnosis.code ? diagnosis.code.toUpperCase() : diagnosis.code}</strong>
                  &nbsp;-&nbsp;
                  {toSentenceCase(diagnosis.description)}
                  &nbsp;
                  <i>{`(${diagnosis.system})`}</i>
                </>
              ) as React.ReactNode
            }
            key={diagnosis.code}
          />
        );
      })}
      <SelectIcdToWriteback resourceType={'referral'} />
    </div>
  );
};

export default ReferralAssessment;
