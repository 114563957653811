import React from 'react';
import type { EHR } from 'vim-os-js-browser/types';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { toSentenceCase } from '../../utils';

const OrderCPTs = ({ order }: { order: EHR.Order }) => {
  return order?.procedureCodes?.procedureCodes?.length ? (
    <div className="section-with-separator last">
      <h2>Procedure Codes</h2>
      {order.procedureCodes.procedureCodes.map((procedure) => {
        return (
          <DetailsCardItem
            value={
              (
                <>
                  <strong>{procedure.code ? procedure.code.toUpperCase() : procedure.code}</strong>
                  &nbsp;-&nbsp;
                  {toSentenceCase(procedure.description)}
                </>
              ) as React.ReactNode
            }
            key={procedure.code}
          />
        );
      })}
    </div>
  ) : null;
};

export default OrderCPTs;
