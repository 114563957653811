import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import './index.less';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface Props {
  value: string;
  label?: string;
  handleOnSave(value: string): void;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const DataPickerInput = (props: Props) => {
  const { value = new Date(), label, handleOnSave } = props;

  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [_value, setValue] = useState<Dayjs | null>(dayjs(value));

  const handleOnChange = useCallback(
    (value) => {
      setValue(value);
      setIsEdited(false);
      handleOnSave(dayjs(value).format(DATE_FORMAT));
    },
    [handleOnSave],
  );

  const toggleEditMode = useCallback(() => {
    setIsEdited(!isEdited);
  }, [isEdited]);

  // const cancelEditMode = useCallback(() => {
  //   setValue(dayjs(value));
  //   setIsEdited(false);
  // }, []);
  //
  // const submit = useCallback(() => {
  //   setIsEdited(false);
  //   handleOnSave(dayjs(_value).format(DATE_FORMAT));
  // }, [_value, handleOnSave]);

  return (
    <div className="data-picker">
      <label>{label}</label>
      <div className="value-input">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disablePast
            className="data-picker__input"
            open={isEdited}
            value={_value}
            onChange={handleOnChange}
            disabled={!isEdited}
            // slotProps={{
            //   actionBar: {
            //     // The actions will be the same between desktop and mobile
            //     actions: ['cancel', 'accept'],
            //   },
            // }}
          />
        </LocalizationProvider>

        {isEdited ? (
          <></>
        ) : (
          // <ButtonGroup variant="outlined" className="action-group">
          //   <Button onClick={cancelEditMode} className="action-button">
          //     <i className="icon-x" />
          //   </Button>
          //   <Button disabled={false} onClick={submit} className="action-button">
          //     <i className="icon-check" />
          //   </Button>
          // </ButtonGroup>
          <Button
            onClick={toggleEditMode}
            className="edit-icon"
            sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
          >
            <i className="icon-pencil" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default DataPickerInput;
