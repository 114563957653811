import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { EHR } from 'vim-os-js-browser/types';

const PatientContactInfo = ({ patient }: { patient: EHR.Patient | undefined }) => {
  return patient?.contact_info ? (
    <div className="section-with-separator">
      <h2>Contact Information</h2>
      {patient?.contact_info?.homePhoneNumber && (
        <DetailsCardItem label="Home phone number" value={patient.contact_info.homePhoneNumber} />
      )}
      {patient?.contact_info?.mobilePhoneNumber && (
        <DetailsCardItem
          label="Mobile phone number"
          value={patient.contact_info.mobilePhoneNumber}
        />
      )}
      {patient?.contact_info?.email && (
        <DetailsCardItem label="Email" value={patient.contact_info.email} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default PatientContactInfo;
