import { Button, ButtonGroup, TextareaAutosize } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.less';

interface Props {
  value: string;
  label?: string;
  placeholder?: string;
  handleOnSave(value: string): void;
}

const EditableTextAreaInput = (props: Props) => {
  const { value = '', placeholder, label, handleOnSave } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [_value, setValue] = useState(value);

  const handleOnChange = useCallback((event) => {
    setValue(event?.target?.value ?? '');
  }, []);
  useEffect(() => {
    if (isEdited && textareaRef.current) {
      const length = textareaRef.current.value.length;
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(length, length);
    }
  }, [isEdited]);

  const toggleEditMode = useCallback(() => {
    setIsEdited(!isEdited);
  }, [isEdited]);

  const toggleEditModeTextAreaClick = useCallback(() => {
    if (!isEdited) {
      setIsEdited(!isEdited);
    }
  }, [isEdited]);

  const cancelEditMode = useCallback(() => {
    setValue('');
    setIsEdited(false);
  }, []);

  const submit = useCallback(() => {
    setIsEdited(false);
    handleOnSave(_value);
  }, [_value, handleOnSave]);

  return (
    <div className="editable-input">
      <label>{label}</label>
      <div className="value-input">
        <TextareaAutosize
          ref={textareaRef}
          className="text-area"
          minRows={3}
          maxRows={3}
          placeholder={placeholder}
          value={_value}
          onChange={handleOnChange}
          disabled={!isEdited}
        />
        {isEdited ? (
          <ButtonGroup variant="outlined" className="action-group">
            <Button onClick={cancelEditMode} className="action-button">
              <i className="icon-x" />
            </Button>
            <Button disabled={_value.length < 1} onClick={submit} className="action-button">
              <i className="icon-check" />
            </Button>
          </ButtonGroup>
        ) : (
          <>
            <div onClick={toggleEditModeTextAreaClick} className="click-zone"></div>
            <Button
              onClick={toggleEditMode}
              className="edit-icon"
              sx={{ '&.MuiButton-root:hover': { bgcolor: 'transparent' } }}
            >
              <i className="icon-pencil" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditableTextAreaInput;
