import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import type { EHR } from 'vim-os-js-browser/types';

const EncounterProviderDetails = ({ encounter }: { encounter: EHR.Encounter | undefined }) => {
  return (
    ((encounter?.provider?.ehrProviderId ||
      encounter?.provider?.npi ||
      encounter?.provider?.demographics?.firstName) && (
      <>
        <div className="section-with-separator">
          <h2>Provider Identifiers</h2>
          {encounter?.provider?.ehrProviderId && (
            <DetailsCardItem label="EHR ID" value={encounter.provider.ehrProviderId} />
          )}
          {encounter?.provider?.npi && (
            <DetailsCardItem label="NPI" value={encounter.provider.npi} />
          )}
        </div>
        <div className="section-with-separator last">
          <h2>Provider Demographics</h2>
          {encounter?.provider?.demographics?.firstName && (
            <DetailsCardItem label="First name" value={encounter.provider.demographics.firstName} />
          )}
          {encounter?.provider?.demographics?.lastName && (
            <DetailsCardItem label="Last name" value={encounter.provider.demographics.lastName} />
          )}
          {encounter?.provider?.demographics?.middleName && (
            <DetailsCardItem
              label="Middle name"
              value={encounter.provider.demographics.middleName}
            />
          )}
          {encounter?.provider?.providerDegree && (
            <DetailsCardItem label="Degree" value={encounter.provider.providerDegree} />
          )}
          {encounter?.provider?.facility?.name && (
            <DetailsCardItem label="Organization name" value={encounter.provider.facility?.name} />
          )}
        </div>
      </>
    )) ||
    null
  );
};

export default EncounterProviderDetails;
