import React from 'react';
import { DetailsCardItem } from '@getvim/atomic-ui';
import { EHR } from 'vim-os-js-browser/types';

const PatientDemographics = ({ patient }: { patient: EHR.Patient | undefined }) => {
  return (
    <>
      {patient?.identifiers?.mrn && (
        <div className="section-with-separator">
          <h2>Identifiers</h2>
          <DetailsCardItem label="External MRN ID" value={patient.identifiers.mrn} />
        </div>
      )}
      {patient?.demographics?.firstName && (
        <div className="section-with-separator">
          <h2>Demographics</h2>
          {patient?.demographics?.firstName && (
            <DetailsCardItem label="First name" value={patient.demographics.firstName} />
          )}
          {patient?.demographics?.lastName && (
            <DetailsCardItem label="Last name" value={patient.demographics.lastName} />
          )}
          {patient?.demographics?.dateOfBirth && (
            <DetailsCardItem label="Date of birth" value={patient.demographics.dateOfBirth} />
          )}
          {patient?.demographics?.gender && (
            <DetailsCardItem label="Gender" value={patient.demographics.gender} />
          )}
        </div>
      )}
    </>
  );
};

export default PatientDemographics;
