import React, { useCallback, useContext, useState } from 'react';
import EditableInput from '../common/editable-text-area-input/EditableTextAreaInput';
import { useVimWritebackWrapper } from '../../hooks/useVimWritebackWrapper';
import { VimOSContext } from '../../vimOsContext';

const EncounterObjective = () => {
  const writeback = useVimWritebackWrapper();
  const { ehr } = useContext(VimOSContext);
  const { resourceUpdater } = ehr;

  const [generalNotes, setGeneralNotes] = useState('');
  const [physicalExamNotes, setPhysicalExamNotesNotes] = useState('');

  const onSubmitGeneralNotes = useCallback(
    (generalNotes: string) => {
      setGeneralNotes(generalNotes);
      return writeback(resourceUpdater.updateEncounter({ objective: { generalNotes } }));
    },
    [resourceUpdater, writeback],
  );

  const onSubmitPhysicalExamNotes = useCallback(
    (physicalExamNotes: string) => {
      setPhysicalExamNotesNotes(physicalExamNotes);
      return writeback(resourceUpdater.updateEncounter({ objective: { physicalExamNotes } }));
    },
    [resourceUpdater, writeback],
  );

  return (
    <div className="section-with-separator">
      <h2>Objective</h2>
      <EditableInput
        value={generalNotes}
        label="General notes"
        placeholder="Add notes here"
        handleOnSave={onSubmitGeneralNotes}
      />

      <EditableInput
        value={physicalExamNotes}
        label="Physical Exam notes"
        placeholder="Add notes here"
        handleOnSave={onSubmitPhysicalExamNotes}
      />
    </div>
  );
};

export default EncounterObjective;
